@import 'variables';

@keyframes wiggle {
  0% {
    transform: rotateZ(24deg);
  }
  50% {
    transform: rotateZ(-24deg);
  }
  100% {
    transform: rotateZ(24deg);
  }
}

.AlertsCountBadge {
  background-color: $red;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  font-weight: 400;
  margin-left: 50%;
  padding: 1px 6px;

  &Hide {
    opacity: 0;
  }
}

.Animate {
  animation: wiggle 0.2s ease 10;
}
