@import 'variables';

.Form {
  text-align: left;

  h2 {
    font-weight: bold;
  }

  ::placeholder {
    color: $gray;
    font-size: 14px;
    font-style: italic;
  }
}

.ForgotPassword {
  color: $blue;
  font-size: 14px;
  margin-top: 0.6em;
}

.RightAlignedRow {
  flex-direction: row-reverse;
  margin: 0;
  padding-bottom: 32px;
}
