.TransparentButton {
  --webkit-appearance: none;
  --moz-appearance: none;
  background-color: transparent;
  border: 0;
  height: 100%;
  outline: 0px;
  padding: 0px;

  &:focus {
    outline: none;
  }
}
