@import 'mixins';
@import 'variables';

.Container {
  background-color: $white;
  border-radius: 5px;
  flex-grow: 1;
  max-width: 550px;
  padding: 20px 20px 44px 20px;
  width: 100%;

  @include desktop {
    margin: 0 44px;
  }
}

.Image {
  max-height: 530px;
  object-fit: scale-down;
}
