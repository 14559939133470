@import 'variables';

.DropdownItem {
  color: $gray--black;
  font-size: 0.875rem;
  padding: 0.25rem;

  &:hover {
    cursor: pointer;
  }
}

.UserIconWrapper {
  height: 44px;
  padding: 0 16px;

  &:hover {
    cursor: pointer;
  }
}

.UserIcon {
  align-items: flex-start;
  background: transparent url($user-black-icon-bg) no-repeat 0 4px;
  display: flex;
  float: right;
  height: 100%;
  justify-items: flex-end;
  margin: 0 0 0 0;
  min-width: 35px;
  padding: 3px 0 0 17px;
}
