@import 'variables';

.HeaderLinkText {
  color: $black;
  font-size: 1rem;
}

.HeaderLink {
  &:hover {
    background-color: $blue;
    border-radius: 22px;

    .HeaderLinkText {
      color: $white;
    }
  }
}
