@import 'variables';

.Footer {
  background: $white;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  position: sticky;
  z-index: 999;
  div {
    align-items: center;
    display: flex;
    p {
      color: $black;
      font-size: 10px;
      font-weight: 300;
      margin-bottom: 0;
      margin-left: 16px;
    }

    svg {
      max-height: 20px;
      max-width: 60px;
    }

    a {
      color: $black;
      font-size: 10px;
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}
