@import 'variables';

//
// Bootstrap overrides
//
$border-radius: 2px;
$border-radius-lg: 2px;
$font-family-base: 'Poppins', sans-serif;

// Buttons
$btn-border-radius: 22px;
$btn-font-weight: bold;
$btn-line-height: 21px;
$btn-padding-y: 12px;
$btn-white-space: nowrap;

// Buttons (Small)
$btn-border-radius-sm: 22px;
$btn-padding-x-sm: 20px;
$btn-padding-y-sm: 3px;

// Colors
$dark: $gray--black;
$primary: $hpe-green;
$secondary: $gray;

// Custom controls
$custom-control-indicator-border-width: 3px;
$custom-control-indicator-checked-bg: $white;
$custom-control-indicator-checked-border-color: $blue;
$custom-control-indicator-checked-color: $blue;
$custom-control-indicator-size: 2rem;

// Input
$input-border-color: $gray--dark;
$input-border-radius: 5px;
$input-color: $black;
$input-font-size: 14px;
$input-height: 52px;
$input-placeholder-color: $gray;

// Links
$link-color: $blue;
$link-decoration: none;
$link-hover-decoration: none;

// Modals
$modal-content-border-radius: 14px;

$navbar-toggler-border-radius: $border-radius;

// Select
$custom-select-border-radius: 5px;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");

@import '~bootstrap/scss/bootstrap';

html,
body,
#root {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

* {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
}

pre {
  font-family: 'Poppins', sans-serif !important;
}

tr.table-active td {
  background-color: $yellow--light !important;
}

// Form overrides
.form-control:focus::placeholder {
  color: transparent;
  opacity: 0;
}

.form-label {
  font-size: 14px;
  font-weight: bold;
  padding-left: 12px;
}

// Modal overrides
.modal {
  padding-top: 70px;
}

.modal-header {
  font-size: 24px;
  margin: 0 16px;

  .modal-title {
    font-weight: bold;
  }
}

.modal-header .close {
  align-self: center;
  font-size: 2rem;
  opacity: 1;
}

// Spinner overrides
.spinner-border {
  color: $green !important;
}

button .spinner-border {
  color: $white !important;
}

// Text color overrides
.text-error {
  color: $orange;
}

// Google Address Autocomplete
.pac-container {
  z-index: 99999;
}
