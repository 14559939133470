@import 'variables';

.BellIconWrapper {
  height: 44px;
}

.BellIconButton {
  align-items: flex-start;
  background: transparent url($bell-black-icon-bg) no-repeat 0 4px;
  display: flex;
  float: right;
  height: 100%;
  justify-items: flex-end;
  margin: 0 0 0 16px;
  min-width: 24px;
  padding: 3px 0 0 17px;
}
