@import 'mixins';
@import 'variables';

.Button {
  background-color: transparent;
  border: none;
  border-radius: 2px;
  color: $black;
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 0.25rem 1rem;

  @include desktop {
    margin-bottom: 0;
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    background-color: $blue;
    border-radius: 20px;
    color: $white;
  }
}

.DropdownItem {
  color: $gray--black;
  padding: 0.25rem;
}

.SubMenu {
  background-color: $gray--light;
  max-height: 0;
  overflow: hidden;
  transition: margin-bottom 1s ease, max-height 0.5s ease;
  width: 100%;

  @include desktop {
    display: none;
  }

  &.Open {
    margin-bottom: 1rem;
    max-height: 500px;
  }

  a {
    padding: 1rem;
    text-align: right;

    &:hover {
      background-color: $gray--light;
    }
  }
}
