@import 'variables';

.HeaderLogoContainer {
  align-items: center;
  min-height: 200px;
  padding: 0 100px;

  .HeaderLogo {
    font-size: 1.2rem;
    height: 55px;
    padding-right: 1rem;
    width: 100%;

    &.InModal {
      height: 40px;
    }
  }
}

.FormRow {
  background-color: $gray--light;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 24px;
  width: 100%;
}

.FormContainer {
  margin: 0 70px;
  width: 100%;
}
