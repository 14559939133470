@import 'mixins';
@import 'variables';

$header-font-family: Poppins, sans-serif;
$header-padding: 1rem 1rem 1rem 2.375rem;
$header-padding-mobile: 1rem;
$header-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
$logo-height: 37px;
$logo-width: 177px;

.Header {
  align-items: center;
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 73px;
  padding: $header-padding-mobile;
  position: fixed;
  width: 100%;
  z-index: 99999;

  * {
    font-family: $header-font-family;
  }

  &.Scrolled,
  &.Active {
    box-shadow: $header-shadow;
  }

  @include tablet-and-up {
    padding: $header-padding;
  }
}

.HeaderLink {
  color: $black;

  &:hover {
    color: $blue;
  }
}

.HeaderLogo {
  display: none;
  height: $logo-height;
  width: $logo-width;

  @include tablet-and-up {
    display: unset;
    margin-left: 0.5rem;
  }
}

.HeaderLogoMobile {
  height: $logo-height;

  @include tablet-and-up {
    display: none;
  }
}

.HeaderPageTitle {
  flex-grow: 1;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}
