$alert-color: #721c24;
$alert-background: #f8d7da;
$alert-border-color: #f5c6cb;

.ErrorDialog {
  :global(.modal-content) {
    background: $alert-background;
    border-color: $alert-border-color;
    color: $alert-color;
  }
  :global(.modal-header) {
    border-color: $alert-border-color;
  }
}

.ErrorDialogButton {
  background-color: $alert-color;
  border-color: $alert-border-color;
  width: 100%;
  &:hover,
  &:active {
    background-color: lighten($alert-color, 10%) !important;
    border-color: $alert-background !important;
  }
}
