@import 'mixins';
@import 'variables';

.HeaderMenu {
  align-items: flex-end;
  background-color: $white;
  display: flex;
  flex: 1 0 100%;
  flex-flow: column;
  margin: 0 -1rem;
  max-height: 0;
  overflow-y: hidden;
  padding-left: 0;
  transition: max-height 0.5s, padding 0.75s;
  z-index: 999999;

  @include desktop {
    align-items: center;
    flex: unset;
    flex-flow: row;
    margin: 0;
    max-height: unset;
    overflow: visible;
  }

  &.HeaderMenuActive {
    box-shadow: 0 4px 4px 0 $black-transparent-50;
    max-height: 500px;
    padding-bottom: 1rem;
    padding-top: 1rem;

    @include desktop {
      padding-top: unset;
    }
  }

  .MenuItem {
    color: $black;
    padding-bottom: 1rem;
    text-align: right;

    @include desktop {
      padding-bottom: unset;
    }

    li {
      display: inline-block;
      padding: 0.25rem 1rem;
      text-align: right;
    }

    &:hover {
      li {
        background-color: $blue;
        border-radius: 20px;
        color: $white;
      }
    }

    &:last-child {
      padding-bottom: unset;
    }
  }

  .UserMenu {
    .UserMenuPopover {
      display: flex;
      flex-direction: column;
    }
  }

  @include desktop {
    .UserMenu {
      background: transparent url($user-black-icon-bg) no-repeat 0 4px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 38px;
      margin: 0 1rem 0 2rem;
      position: relative;
      width: 38px;

      .UserMenuPopover {
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 $black;
        display: none;
        flex-direction: column;
        padding: 0.5rem;
        position: absolute;
        right: -1.75rem;
        top: calc(100% + 11px);
        width: 110px;

        .MenuItem {
          font-size: 13px;
          padding: 0;
          text-align: left;

          li {
            border-radius: 0;
            padding: 2px 4px;
          }

          &:hover {
            li {
              background-color: $white;
              color: $blue;
            }
          }
        }

        &:hover {
          display: flex;
        }

        &::before {
          background-color: transparent;
          border-bottom: 11px solid $gray--light;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          content: ' ';
          left: 50%;
          position: absolute;
          top: -11px;
          width: 4px;
        }
      }

      &:hover {
        .UserMenuPopover {
          display: flex;
        }
      }

      &.Hovered {
        .UserMenuPopover {
          display: flex;
        }
      }
    }
  }
}

.AlertCount {
  align-items: center;
  background-color: $red;
  border-radius: 9999px;
  color: $white;
  display: none;
  font-size: 14px;
  height: 24px;
  justify-content: center;
  min-width: 24px;
  padding: 0 5px;
  position: absolute;
  right: -20px;
  top: -5px;

  @include desktop {
    display: flex;
  }
}
