/* stylelint-disable */

@import 'mixins';
@import 'variables';

.HeaderToggle {
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  height: 34px;
  padding: 6px;
  position: relative;

  @include desktop {
    display: none;
  }

  &:focus {
    outline: 5px auto $green;
  }

  // Squeeze animation from https://jonsuh.com/hamburgers/
  .HeaderToggleInner {
    & {
      transition-duration: 75ms;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &,
    &::after,
    &::before {
      background-color: $black;
      border-radius: 4px;
      height: 6px;
      position: relative;
      transition-property: transform;
      width: 34px;
    }

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
    }

    &::after {
      bottom: -10px;
      transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &::before {
      top: -10px;
      transition: top 75ms ease 0.12s, opacity 75ms ease;
    }
  }

  &.HeaderToggleActive {
    .HeaderToggleInner {
      & {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
      }

      &::before {
        opacity: 0;
        top: 0;
        transition: top 75ms ease, opacity 75ms ease 0.12s;
      }
    }
  }
}

/* stylelint-enable */
