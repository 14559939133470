@import 'variables';

#tooltip-user-account-icon {
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $white;
  }

  &.tooltip.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $gray--white;
    border-radius: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    color: $black;
    max-width: unset;
    min-width: 8.25rem;
    text-align: left;
  }
}
