@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

$tablet: 768px;
$desktop: 1024px;
$desktop-xl: 1200px;
$desktop-xxl: 1400px;

@mixin tablet-and-up {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: $desktop-xl) {
    @content;
  }
}

@mixin desktop-xxl {
  @media (min-width: $desktop-xxl) {
    @content;
  }
}
